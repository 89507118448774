import { cn } from "@/lib/utils"
import React, { Fragment, useState } from "react"

export const planTabs = [
  {
    id: "subscription",
    name: "Subscription",
  },
  {
    id: "model-storage",
    name: "Model Storage",
  },
  {
    id: "credits",
    name: "Credits",
  },
  {
    id: "enterprise",
    name: "Enterprise",
  },
]

interface PlanTabsProps {
  children: (props: { tab: string; setTab: (tab: string) => void }) => React.ReactElement
  tabClassName?: string
  childClassName?: string
  isMember?: boolean
}

const PlanTabs = ({ children, tabClassName, isMember, childClassName }: PlanTabsProps) => {
  const [selectedTab, setSelectedTab] = useState(planTabs[0].id)

  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className={cn("flex items-center space-x-8 justify-center pt-6 pb-2", tabClassName)}>
          {planTabs
            .filter(i => (isMember ? i.id === "subscription" : true))
            .map(tab => (
              <div
                className={"cursor-pointer text-xl font-semibold relative"}
                key={tab.id}
                onClick={() => setSelectedTab(tab.id)}
              >
                <p
                  className={cn("z-[1]", {
                    "text-atherGray-0": tab.id === selectedTab,
                    "text-atherGray-500": tab.id !== selectedTab,
                  })}
                >
                  {tab.name}
                </p>
                {tab.id === selectedTab && (
                  <div className="bg-atherPurple-500 pointer-events-none opacity-60 size-[8rem] blur-2xl absolute top-[1.5rem] left-1/2 -translate-x-1/2" />
                )}
              </div>
            ))}
        </div>
        <div
          className="h-[1px] w-full"
          style={{
            backgroundImage: "linear-gradient(90deg, rgba(50, 50, 48, 0) 0%, #323230 50%, rgba(50, 50, 48, 0) 100%)",
          }}
        ></div>
      </div>
      <div className={cn("bg-black mt-8", childClassName)}>
        {children({ tab: selectedTab, setTab: setSelectedTab })}
      </div>
    </Fragment>
  )
}

export default PlanTabs
