import { BillingCycle, CurrencyCode, PaymentMethod, ProductType, SubscriptionGroup, SubscriptionType } from "@/api/sdk"
import { cdnPublicFolderUrl, clientUrl } from "@/constants"
import { useToast } from "@/hooks"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useAuth } from "@/providers/authContext"
import {
  useGetSubscriptionDetailQuery,
  useGetSubscriptionPreviewAmount,
  useSubscriptionPurchaseMutate,
} from "@/queries"
import { cn } from "@/utils/cn"
import { useWeb3Modal } from "@web3modal/wagmi/react"
import classNames from "classnames"
import Link from "next/link"
import { Fragment, useEffect, useState } from "react"
import { useAccount } from "wagmi"
import { Radio } from "../Alpha/View/GalleryFilter"
import BackButton from "../BackButton"
import IconButton from "../IconButton"
import Input from "../Input"
import LoadingLogo from "../LoadingLogo"
import CryptoPaymentModal from "../ModalsPortal/CryptoPaymentModal"
import { DeleteIcon, ETHIcon } from "../shared/icons"
import { subscriptionTabs } from "./Plans"
import Payment2C2PModal from "./Plans/Payment2C2PModal"
import StripeCheckoutModal from "./Plans/StripeCheckoutModal"

export const paymentMethods = [
  {
    id: PaymentMethod.CRYPTO,
    name: "Ethereum Wallet",
    icon: `${cdnPublicFolderUrl}/eth-diamond-purple.png`,
    isDisabled: false,
  },
  {
    id: PaymentMethod.STRIPE,
    name: "STRIPE",
    icon: `${cdnPublicFolderUrl}/stripe.svg`,
    isDisabled: false,
  },
]

const UpgradeAccount = () => {
  const router = useCustomRouter()
  const [isOpen, setIsOpen] = useState(false)
  const [inputCode, setInputCode] = useState("")
  const [coupons, setCoupons] = useState<string[]>([])
  const toast = useToast()
  const { subscriptionId } = router.query
  const [selectedTab, setSelectedTab] = useState("Monthly")
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(paymentMethods[0].id)
  const [cryptoPayment, setCryptoPayment] = useState<{
    productType: ProductType
    productId: string
    workspaceId?: string
    subscriptionType?: SubscriptionType
    billingCycle?: BillingCycle
    seatCount?: number
  } | null>(null)
  const { userInfoQuery, user } = useAuth()
  const { isConnected } = useAccount()
  const { open } = useWeb3Modal()
  const [paymentUrl, setPaymentUrl] = useState<string | null>(null)
  const [stripeClientSecret, setStripeClientSecret] = useState<string | null>(null)

  const { data: subscription, isLoading } = useGetSubscriptionDetailQuery({
    variables: {
      subscriptionId: subscriptionId as string,
    },
  })

  const { mutate: mutatePurchase, isPending: isMutatingPurchase } = useSubscriptionPurchaseMutate({
    onSuccess: data => {
      setPaymentUrl(data.url)
      if (data.clientSecret) {
        setStripeClientSecret(data.clientSecret)
      }
    },
    onError: error => {
      toast({
        title: "Error",
        message: [error?.message || "Something went wrong"],
        status: "error",
      })
    },
  })

  const { data: previewAmount } = useGetSubscriptionPreviewAmount({
    variables: {
      subscriptionPackageId: subscriptionId as string,
      workspaceId: userInfoQuery?.data?.currentWorkspace.id ?? "",
      currencyCode: CurrencyCode.USD,
      subscriptionType: SubscriptionType.Upgrade,
      billingCycle:
        selectedTab === "Monthly"
          ? BillingCycle.Monthly
          : selectedTab === "Bi-Yearly"
            ? BillingCycle.BiYearly
            : BillingCycle.Yearly,
    },
  })

  const handleBack = () => {
    router.replace("/settings/account?tab=Plans")
  }

  const handleProcessPayment = () => {
    if (!userInfoQuery?.data?.currentWorkspace) return

    if (selectedPaymentMethod === PaymentMethod.XSOLLA) {
      mutatePurchase({
        data: {
          subscriptionPackageId: subscriptionId as string,
          workspaceId: userInfoQuery?.data?.currentWorkspace.id,
          currencyCode: CurrencyCode.VND,
          subscriptionType: SubscriptionType.Upgrade,
          frontendReturnUrl: `${clientUrl}/settings/account?tab=Plans`,
          billingCycle:
            selectedTab === "Monthly"
              ? BillingCycle.Monthly
              : selectedTab === "Bi-Yearly"
                ? BillingCycle.BiYearly
                : BillingCycle.Yearly,
        },
        userUid: user?.uid ?? "",
      })
    } else if (selectedPaymentMethod === PaymentMethod.Value2C2P) {
      mutatePurchase({
        data: {
          paymentMethod: PaymentMethod.Value2C2P,
          subscriptionPackageId: subscriptionId as string,
          workspaceId: userInfoQuery?.data?.currentWorkspace.id,
          currencyCode: CurrencyCode.VND,
          subscriptionType: SubscriptionType.Upgrade,
          frontendReturnUrl: `${clientUrl}/settings/account?tab=Plans`,
          billingCycle:
            selectedTab === "Monthly"
              ? BillingCycle.Monthly
              : selectedTab === "Bi-Yearly"
                ? BillingCycle.BiYearly
                : BillingCycle.Yearly,
        },
        userUid: user?.uid ?? "",
      })
    } else if (selectedPaymentMethod === PaymentMethod.CRYPTO) {
      if (!isConnected) {
        open()
        return
      }
      setCryptoPayment({
        productType: ProductType.Subscription,
        productId: subscriptionId as string,
        workspaceId: userInfoQuery?.data?.currentWorkspace.id,
        subscriptionType: SubscriptionType.Upgrade,
        billingCycle:
          selectedTab === "Monthly"
            ? BillingCycle.Monthly
            : selectedTab === "Bi-Yearly"
              ? BillingCycle.BiYearly
              : BillingCycle.Yearly,
        seatCount: previewAmount?.seatCount,
      })
    } else if (selectedPaymentMethod === PaymentMethod.STRIPE) {
      mutatePurchase({
        data: {
          paymentMethod: PaymentMethod.STRIPE,
          subscriptionPackageId: subscriptionId as string,
          workspaceId: userInfoQuery?.data?.currentWorkspace.id,
          currencyCode: CurrencyCode.VND,
          subscriptionType: SubscriptionType.Upgrade,
          frontendReturnUrl: `${clientUrl}/settings/account?tab=Plans`,
          billingCycle:
            selectedTab === "Monthly"
              ? BillingCycle.Monthly
              : selectedTab === "Bi-Yearly"
                ? BillingCycle.BiYearly
                : BillingCycle.Yearly,
        },
        userUid: user?.uid ?? "",
      })
    }
  }

  useEffect(() => {
    if (router.isReady) {
      setSelectedTab(subscriptionId === "newbie" ? "Yearly" : (router.query.tab as string) || "Monthly")
    }
  }, [router.isReady])

  if (isLoading)
    return (
      <div className="flex items-center justify-center flex-1 w-full">
        <LoadingLogo />
      </div>
    )

  if (!subscription) return null

  return (
    <div className="w-full flex-1 flex flex-col items-center p-4 text-atherGray-100">
      <BackButton className="fixed top-[6.5rem] md:top-[7rem] left-4 md:left-6" onClick={() => handleBack()} />
      <div className="w-full max-w-[72rem] py-20">
        <h2 className="text-3xl font-semibold text-center mb-2 text-atherGray-100">Upgrade your plan</h2>
        <p className="text-atherGray-300 text-center mb-8">
          Get additional power and control to help your team work more effectively
        </p>
        <div className="flex text-atherGray-100 flex-col lg:flex-row">
          <div className="flex-1">
            <div className="bg-atherGray-900 p-2 md:p-4 rounded-2xl mb-4">
              <p className="mb-4 font-semibold">Your plan</p>
              <div className="flex items-center space-x-2 mb-4">
                <div className="flex items-center justify-center space-x-2 mb-4">
                  {subscriptionTabs.map((tab, index) => (
                    <IconButton
                      disabled={subscriptionId === "newbie" && tab.id !== "Yearly"}
                      colorScheme="secondary"
                      className={cn(
                        "bg-transparent border py-1.5 px-3 text-xs md:text-base text-atherGray-300  whitespace-nowrap border-atherGray-800 rounded-full",
                        {
                          "bg-atherGray-800 text-atherGray-100": tab.id === selectedTab,
                        },
                      )}
                      key={index}
                      onClick={() => setSelectedTab(tab.id)}
                    >
                      {tab.id}
                      {tab.sale && (
                        <div className="ml-1 md:ml-2 bg-atherPurple-500 py-1 px-2 flex justify-center items-center font-semibold text-[#EAE4D4] rounded-lg relative">
                          <span
                            className="bg-atherPurple-500 absolute -left-[0.45rem] top-1/2 -translate-y-1/2 w-2 h-2 rotate-180"
                            style={{
                              clipPath: "polygon(80% 50%, 0 0, 0 100%)",
                            }}
                          />
                          <p className="text-[0.65rem] md:text-xs">Save {tab.sale}</p>
                        </div>
                      )}
                    </IconButton>
                  ))}
                </div>
              </div>
              <div className="flex items-center justify-between">
                <h3 className="text-xl font-semibold mr-2">Upgrade to “{subscription.name}”</h3>
                <h3 className="text-xl font-semibold">
                  $
                  {subscription.group === SubscriptionGroup.ModelStorage
                    ? previewAmount?.amount
                    : previewAmount?.amountPerSeat}
                </h3>
              </div>
            </div>
            <div className="bg-atherGray-900 p-2 md:p-4 rounded-2xl">
              <p className="font-semibold">Payment method</p>
              <hr className="border-atherGray-800 my-4" />
              <div className="flex flex-col space-y-4">
                {paymentMethods.map((method, index) => (
                  <Fragment key={method.id}>
                    {index !== 0 && <hr className="border-atherGray-800" />}
                    <div
                      className={classNames("py-2", {
                        "opacity-40 cursor-not-allowed": method.isDisabled,
                      })}
                    >
                      <Radio
                        name={method.name}
                        labelClassName={"text-base font-semibold"}
                        containerClassName={classNames({
                          "cursor-not-allowed": method.isDisabled,
                        })}
                        value={method.id}
                        leftIcon={
                          <div className="ml-4 rounded-md overflow-hidden">
                            {method.id === PaymentMethod.CRYPTO ? (
                              <div className="w-8 h-8 p-0.5">
                                <div className="bg-[#627EEA] w-full h-full rounded-full flex items-center justify-center">
                                  <ETHIcon />
                                </div>
                              </div>
                            ) : (
                              <img src={method.icon} alt="" className="w-8 h-8" />
                            )}
                          </div>
                        }
                        checked={selectedPaymentMethod === method.id}
                        onChange={() => {
                          if (method.isDisabled) return
                          setSelectedPaymentMethod(method.id)
                        }}
                      />
                    </div>
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
          <div className="lg:w-80 mt-4 lg:mt-0 lg:ml-20">
            <div className="w-full h-full bg-atherGray-900 p-2 md:p-4 rounded-2xl space-y-4">
              <div className="flex items-center space-x-1">
                <p className="font-semibold">Payment</p>
              </div>
              <div className="text-sm flex items-center justify-between">
                <div className="flex flex-col">
                  <p className="flex-1 mr-2 font-semibold text-atherGray-100">{subscription.name}</p>
                  {subscription.group === SubscriptionGroup.Regular && previewAmount?.seatCount && (
                    <p className="text-atherGray-300 text-xs">x {previewAmount?.seatCount} seats</p>
                  )}
                </div>
                <div className="flex flex-col text-right">
                  <p className="text-atherGray-100">
                    $
                    {subscription.group === SubscriptionGroup.ModelStorage
                      ? previewAmount?.amount
                      : previewAmount?.amountPerSeat}
                  </p>
                  {subscription.group === SubscriptionGroup.Regular && previewAmount?.seatCount && (
                    <p className="text-atherGray-300 text-xs">user/month</p>
                  )}
                </div>
              </div>
              <Input
                value={inputCode}
                onChange={e => setInputCode(e.target.value)}
                className="rounded-lg text-sm placeholder:text-atherGray-500 pr-16"
                placeholder="Promotion code"
                rightIcon={
                  <button
                    type="button"
                    disabled={!inputCode}
                    className={cn("font-semibold text-xs", {
                      "text-atherGray-500 !cursor-not-allowed": !inputCode,
                      "text-atherGray-100": inputCode,
                    })}
                    onClick={() => {
                      // setCoupons([...coupons, inputCode])
                      // setInputCode("")

                      toast({
                        title: "Error",
                        message: ["Promotion code is invalid"],
                        status: "error",
                      })
                    }}
                  >
                    Apply
                  </button>
                }
              />
              {coupons.length > 0 && (
                <div className="space-y-2">
                  {coupons.map(coupon => (
                    <div key={coupon} className="flex items-center justify-between text-sm relative">
                      <div
                        className="px-4 py-1 h-full bg-atherGray-800 flex items-center"
                        style={{
                          clipPath:
                            "polygon(100% 0, 90% 25%, 100% 50%, 90% 75%, 100% 100%, 0 100%, 10% 75%, 0 50%, 10% 25%, 0 0)",
                        }}
                      >
                        <p className="flex-1 text-sm">{coupon}</p>
                        <button
                          onClick={() => setCoupons(coupons.filter(c => c !== coupon))}
                          type="button"
                          className="ml-2 text-red-500"
                        >
                          <DeleteIcon width={14} height={14} />
                        </button>
                      </div>
                      <p className="text-atherGray-100">-10%</p>
                    </div>
                  ))}
                </div>
              )}
              <hr className="border-atherGray-800" />
              <div className="font-semibold flex items-center">
                <p className="flex-1 mr-2">Total</p>
                <p>${previewAmount?.amount}</p>
              </div>
              <div className="flex flex-col space-y-4">
                <IconButton
                  isLoading={isMutatingPurchase}
                  onClick={handleProcessPayment}
                  className="py-2 text-atherGray-100"
                >
                  Process to Payment
                </IconButton>
                <IconButton onClick={() => handleBack()} className="py-2 text-atherGray-100" colorScheme="secondary">
                  Cancel upgrade
                </IconButton>
              </div>
              <p className="text-xs text-atherGray-500 text-center">
                By continuing, you are agreeing to GAIA&apos;s{" "}
                <Link target="_blank" className="text-atherGray-100 underline" href={"/terms-of-service"}>
                  terms & conditions
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Payment2C2PModal
        onSuccess={() => {
          router.replace("/settings/account?tab=Plans")
        }}
        isOpen={paymentUrl}
        onClose={() => {
          setPaymentUrl(null)
        }}
      />

      {cryptoPayment && <CryptoPaymentModal cryptoPayment={cryptoPayment} onClose={() => setCryptoPayment(null)} />}
      <StripeCheckoutModal
        clientSecret={stripeClientSecret}
        isOpen={!!stripeClientSecret}
        onClose={() => {
          setStripeClientSecret(null)
        }}
      />
    </div>
  )
}

export default UpgradeAccount
